import React, { Component } from "react";
import { Link } from "gatsby";
import { Typography } from "antd";
import Layout from "../layouts/en";
import SEO from "../components/seo";
import "../style/loyalty.less";

const { Title, Text } = Typography;

export default class LoyaltyUpdatePage extends Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <SEO
          title="Loyalty Update | "
          keyword="Loyalty Update"
          decription="Loyalty Update"
        />
        <div id="contentWrap" className="loyalty-content">
          <div className="container container1200">
            <div id="index_content" className="admin-setting">
              <Title
                level={3}
                strong
                className="f-secondary d-block align-center mb-30">
                AYANA REWARDS LOYALTY UPDATE
              </Title>
              <ul className="loyalty-list">
                <li>
                  <span className="d-block mb-5">
                    <strong>Status Update</strong>
                  </span>
                  <Text className="d-block">
                    For gold members, only 1,500 points required a year to
                    maintain gold status while to be upgraded to Platinum, gold
                    members just need to collect&nbsp;5,000 points a year.
                    <br />
                    For platinum members, only 5,000 points required a year to
                    maintain platinum status.
                  </Text>
                </li>
                <li>
                  <span className="d-block mb-5">
                    <strong>Status Extension</strong>
                  </span>
                  <Text className="d-block">
                    All members whose status was scheduled to be downgraded on
                    March, 2020 &ndash; December, 2020 will be automatically
                    extended until December 31, 2020.
                    <br />
                    If 1,000 points have been collected throughout the period,
                    member will receive a tier status extension.
                    <br />
                    Otherwise, member will be downgraded on January 1, 2021.
                  </Text>
                </li>
                <li>
                  <span className="d-block mb-5">
                    <strong>Points Extension</strong>
                  </span>
                  <Text className="d-block">
                    Instead of 2 years, all points issued starting from March
                    2018 will be valid for 10 years. Members will have more time
                    to collect and redeem points to the dream rewards.
                  </Text>
                </li>
                <li>
                  <span className="d-block mb-5">
                    <strong>Nights Stay Points Redemption</strong>
                  </span>
                  <Text className="d-block">
                    We know the current travel environment&nbsp;may limit your
                    ability to stay with us. Thus, we will reduce the number of
                    points required to stay at any of our Hotels.
                    <br />
                    <br />
                    3 nights stay at AYANA Komodo Resort for 22,000 points
                    <br />
                    2 nights stay at AYANA Komodo Resort for 11,000 points
                    <br />
                    3 nights stay at RIMBA Jimbaran BALI by AYANA for 15,000
                    points
                    <br />
                    2 nights stay at RIMBA Jimbaran BALI by AYANA for 7,500
                    points
                    <br />
                    3 nights stay at AYANA Midplaza, JAKARTA for 11,000 points
                    <br />
                    2 nights stay at AYANA Midplaza, JAKARTA for 5,500 points
                    <br />
                    3 nights stay at Delonix Hotel Karawang for 5,000 points
                    <br />
                    2 nights stay at Delonix Hotel Karawang for 2,500 points
                    <br />
                  </Text>
                  <br />
                  <Text className="d-block small-text">
                    * Redeem period until July 30th, 2020 and stay period until
                    July 31st, 2020
                  </Text>
                  <Text className="d-block small-text">
                    * Redeem through <Link to="/">ayanarewards.com/reward</Link>
                  </Text>
                  <br />
                  <Text className="d-block small-text">
                    For more information, please reach us via email at{" "}
                    <Link to="/">info@ayanarewards.com</Link>
                  </Text>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
